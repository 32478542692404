import React from "react"
import styled from "styled-components"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "gatsby-plugin-intl"
import Layout from "@/components/layout"
import Helmet from "react-helmet"
import { Link } from "gatsby-plugin-intl"

export default injectIntl(({ intl }) => (
  <>
    <Helmet>
      <title>WILLBUD | Dokumenty prawne</title>
    </Helmet>
    <Layout>
      <PageWrapper>
        <Container>
          <Row>
            <Col>
              <h1 className={`title`}>Dokumenty prawne</h1>
              <h2>
                Program „Poleć mieszkanie w&nbsp;inwestycji WILLBUD
                i&nbsp;odbierz 3000&nbsp;zł”
              </h2>
              <ul>
                <li>
                  <Link to={"/dokumenty-prawne/regulamin-3000"}>
                    Regulamin Programu
                  </Link>
                </li>
                <li>
                  <a
                    href="/dokumenty/14_06_2023_WILLBUD_REGULAMIN_3000.pdf"
                    target={`_blank`}
                  >
                    Regulamin Programu <small>[PDF]</small>
                  </a>
                </li>
                <li>
                  <a
                    href="/dokumenty/WILLBUD-formularz-3000-17.03.2023.pdf"
                    target={`_blank`}
                  >
                    Formularz udziału w Programie <small>[PDF]</small>
                  </a>
                </li>
              </ul>
              <hr />
              <h2>{intl.formatMessage({ id: "nav.privacy" })}</h2>
              <ul>
                <li>
                  <Link to={"/polityka-prywatnosci"}>
                    {intl.formatMessage({ id: "nav.privacy" })}
                  </Link>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </PageWrapper>
    </Layout>
  </>
))

export const PageWrapper = styled.div`
  margin-bottom: 100px;
  padding-top: 0;
  font-size: 20px;
  font-weight: 200;

  h1 {
    font-size: 37px;
    margin-bottom: 40px;
  }
  section {
    h2 {
      font-size: 1.1em;
      font-weight: bold;
      margin: 1em 0 0.5em;
    }
    > ol {
      margin-left: 1em;
    }
  }

  ol {
    padding-left: 20px;

    li {
      padding-left: 10px;
      margin-bottom: 1em;

      strong {
        font-weight: 500;
      }
    }
  }

  ul {
    li {
      a {
        small {
          font-size: 0.6em;
        }
      }
    }
  }

  @media (min-width: 1200px) {
    padding-top: 59px;
    h1 {
      font-size: 48px;
      margin-bottom: 40px;
    }
  }
  @media (max-width: 560px) {
    h1 {
      font-size: 30px;
    }
    h2 {
      font-size: 20px;
    }
    ol {
      padding-left: 10px;
      li {
        padding-left: 5px;
      }
    }
  }
`
